import React from "react"

const Contact = ({bgGrayOpacity}) => {
  return (
		<div className={bgGrayOpacity}>
			<div className="container">
				<div className="row pd-top-90 pd-bottom-60">
					<div className="col-lg-4 col-sm-12 d-flex justify-content-center">
						<div className="single-contact-info">
							<p><i className="fa fa-phone" />Chiamaci:</p>
							<h5><a href="tel:+393391745031">(+39) 339 1745031</a></h5>
						</div>
					</div>
					<div className="col-lg-4 col-sm-12 d-flex justify-content-center">
						<div className="single-contact-info">
							<p><i className="fa fa-envelope" />Invia le domande a:</p>
							<h5><a href="mailto:info@sognocasaliguria.it">info@sognocasaliguria.it</a></h5>
						</div>
					</div>
					<div className="col-lg-4 col-sm-12 d-flex justify-content-center">
						<div className="single-contact-info">
							<p><i className="fa fa-map-marker" />Indirizzo:</p>
								<h5>
							<a rel="noreferrer" target="_blank" href="https://goo.gl/maps/y9cWpxkHvtVHEwGu8">
								Corso Dante, 193/195<br/>17021 ALASSIO (SV)
							</a>
						</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
  )
}

export default Contact