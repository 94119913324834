import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"

const ThankYou = () => (
  <Layout>
    <SEO title="Email inviata" />
    <div className="pd-top-160">
      <h1 className="pd-top-60 pd-bottom-60 text-center"><span className="container">Grazie per averci contattato!<br/>Ti risponderemo al più presto</span></h1>
      <Contact bgGrayOpacity="bg-gray-opacity" />
    </div>
  </Layout>
)

export default ThankYou
